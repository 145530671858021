<template>
  <!-- 新增收盘价配置 -->
  <div class="page">
    <!-- form表单 -->
    <el-form
      :model="ruleForm"
      label-width="100px"
      ref="ruleForm"
      :rules="rules"
    >
      <el-form-item label="数字通证：" prop="certificateId">
        <el-select
          v-model="ruleForm.certificateId"
          placeholder="请选择活动区域"
          @change="select"
        >
          <el-option
            v-for="(item, index) in selector"
            :key="index"
            :label="item.tokenName"
            :value="item.certificateId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="时间配置：" prop="time">
        <el-input v-model="ruleForm.time" size=""></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="info" @click="getBack">返回</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >新增</el-button
        >
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        certificateId: "", // 数字通证
        time: "", // 时间配置
      },
      //   验证规则
      rules: {
        certificateId: [
          { required: true, message: "请选择数字通证", trigger: "change" },
        ],
        time: [{ required: true, message: "请输入时间配置", trigger: "blur" }],
      },
      selector: null,
    };
  },
  created() {
    // 获取下拉框
    this.axios.get("/admin/certificate/getDropDownBox", {}).then((res) => {
      if (res.data.code == 200) {
        this.selector = res.data.data;
        console.log(this.selector);
      }
    });
  },

  methods: {
    // 点击新增
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.axios
            .post("/admin/closing/addClosing", this.ruleForm)
            .then((res) => {
              console.log(res);
              if (res.data.code == 200) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                });
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    select(id) {
      this.ruleForm.tokenNum = this.selector[id - 1].tokenNum;
      this.ruleForm.tokenName = this.selector[id - 1].tokenName;
      this.ruleForm.rule = 123;
    },

    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    // 返回
    getBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  padding: 30px;
  width: 400px;
}
</style>
